import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchGoodwillCouponList,
  rewardGoodWillCoupon,
  searchPurchaseOrderService,
} from '../services/wholesale.service';
import { YuorderCoupon } from '../models/yuorderCoupon.model';
import { WholesalePurchaseOrder } from '../models/wholesalePurchaseOrder.model';

import { toast } from 'react-toastify';
export const fetchWholesalePurchaseOrderSearchRecords = createAsyncThunk(
  'wholesale/purchaseOrderSearch',
  async (payload: { displayOrderId: string; supplierCode: string }, thunkAPI) => {
    try {
      const response: any = await searchPurchaseOrderService({
        displayOrderId: payload.displayOrderId,
        supplierCode: payload.supplierCode,
      });

      if (response.isSuccessful && 'data' in response) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.message || "Couldn't fetch wholesale order.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Couldn't fetch wholesale order.");
    }
  },
);
export const fetchGoodwillCouponRewardRecords = createAsyncThunk(
  'wholesale/goodwill-coupon-reward',
  async (payload: { purchaseOrderId: string; couponId: string }, thunkAPI) => {
    try {
      const response: any = await rewardGoodWillCoupon({
        purchaseOrderId: payload.purchaseOrderId,
        couponId: payload.couponId,
      });

      if (response.isSuccessful && 'data' in response) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.message || "Couldn't fetch wholesale order.");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Couldn't fetch wholesale order.");
    }
  },
);
export const fetchGoodwillCouponsRecords = createAsyncThunk('wholesale/goodwill-coupon-list', async (_, thunkAPI) => {
  try {
    const response: any = await fetchGoodwillCouponList();

    if (response.isSuccessful && 'data' in response) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.message || "Couldn't fetch goodwill coupon list");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue("Couldn't fetch goodwill coupon list");
  }
});
interface WholesaleState {
  wholesalePurchaseOrderSearchData: WholesalePurchaseOrder[] | null;
  wholesalePurchaseOrderSearchLoading: boolean;
  wholesalePurchaseOrderSearchError: boolean;
  goodwillCouponsLoading: boolean;
  goodwillCouponsError: boolean;
  goodwillCouponsData: YuorderCoupon[];
  goodwillCouponRewardLoading: boolean;
  goodwillCouponRewardError: boolean;
  // goodwillCouponRewardData: YuorderCoupon | null;
}
const initialState: WholesaleState = {
  wholesalePurchaseOrderSearchData: null,
  wholesalePurchaseOrderSearchLoading: false,
  wholesalePurchaseOrderSearchError: false,
  goodwillCouponsLoading: false,
  goodwillCouponsError: false,
  goodwillCouponsData: <YuorderCoupon[]>[],
  goodwillCouponRewardLoading: false,
  goodwillCouponRewardError: false,
  // goodwillCouponRewardData: null,
};

const wholesaleSlice = createSlice({
  name: 'wholesale',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWholesalePurchaseOrderSearchRecords.pending, (state) => {
        state.wholesalePurchaseOrderSearchLoading = true;
        state.wholesalePurchaseOrderSearchError = false;
      })
      .addCase(fetchWholesalePurchaseOrderSearchRecords.fulfilled, (state, action) => {
        state.wholesalePurchaseOrderSearchLoading = false;
        state.wholesalePurchaseOrderSearchData = action.payload.data;
        state.wholesalePurchaseOrderSearchError = false;
      })
      .addCase(fetchWholesalePurchaseOrderSearchRecords.rejected, (state, action) => {
        state.wholesalePurchaseOrderSearchLoading = false;
        state.wholesalePurchaseOrderSearchError = true;
        toast.error('Error in fetching Order');
      })
      .addCase(fetchGoodwillCouponsRecords.pending, (state) => {
        state.goodwillCouponsLoading = true;
        state.goodwillCouponsError = false;
      })
      .addCase(fetchGoodwillCouponsRecords.fulfilled, (state, action) => {
        state.goodwillCouponsLoading = false;
        state.goodwillCouponsData = action.payload.data;
        state.goodwillCouponsError = false;
      })
      .addCase(fetchGoodwillCouponsRecords.rejected, (state, action) => {
        state.goodwillCouponsLoading = false;
        state.goodwillCouponsError = true;
        toast.error('Error fetching Goodwill Coupon');
      })
      .addCase(fetchGoodwillCouponRewardRecords.pending, (state) => {
        state.goodwillCouponRewardLoading = true;
        state.goodwillCouponRewardError = false;
      })
      .addCase(fetchGoodwillCouponRewardRecords.fulfilled, (state, action) => {
        state.goodwillCouponRewardLoading = false;
        // state.goodwillCouponRewardData = action.payload.data;
        state.goodwillCouponRewardError = false;
        toast.success(action.payload.message);
      })
      .addCase(fetchGoodwillCouponRewardRecords.rejected, (state, action:any) => {
        
        console.log(action.payload)
        state.goodwillCouponRewardLoading = false;
        state.goodwillCouponRewardError = true;
        toast.error(action.payload.response.data.message);
      });
  },
});

export default wholesaleSlice.reducer;
