import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { WholesalePurchaseOrder } from '../../data/models/wholesalePurchaseOrder.model';
import { YuorderCoupon } from '../../data/models/yuorderCoupon.model';
import { RootState } from '../../data/reducers';
import {
  fetchGoodwillCouponRewardRecords,
  fetchGoodwillCouponsRecords,
  fetchWholesalePurchaseOrderSearchRecords,
} from '../../data/reducers/wholesale.reducer';
import './GoodwillCoupon.css';
import WholesalePurchaseOrderCard from './wholesalePurchaseOrderCard';
const GoodwillCoupon = () => {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(fetchGoodwillCouponsRecords());
  }, [dispatch]);

  const [displayOrderId, setDisplayOrderId] = useState('');
  const [supplierCode, setSupplierCode] = useState('');
  const [selectedCouponId, setSelectedCouponId] = useState<string>('');
  const [selectPurchaseOrderId, setSelectedOrderId] = useState<string>('');

  const {
    wholesalePurchaseOrderSearchData,
    wholesalePurchaseOrderSearchLoading,
    wholesalePurchaseOrderSearchError,
    goodwillCouponsData,
    goodwillCouponsLoading,
    goodwillCouponsError,
    // goodwillCouponRewardData,
    goodwillCouponRewardError,
    goodwillCouponRewardLoading,
  } = useSelector((state: RootState) => state.wholesale);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(fetchWholesalePurchaseOrderSearchRecords({ displayOrderId, supplierCode }));
  };

  const handleCheckboxChange = (couponId: string) => {
    if (selectPurchaseOrderId == '') {
      toast.error('No order selected');
    } else {
      setSelectedCouponId(couponId);
    }
  };
  const handleOrderSelection = (purchaseOrderId: string) => {
    setSelectedOrderId(purchaseOrderId);
  };

  const handleAssignCoupon = () => {
    if (selectPurchaseOrderId != '' && selectedCouponId.length > 0) {
      dispatch(
        fetchGoodwillCouponRewardRecords({
          purchaseOrderId: selectPurchaseOrderId,
          couponId: selectedCouponId,
        }),
      );
      console.log('Assigned Coupons:', selectedCouponId);
    }
  };

  return (
    <div className="container">
      <ToastContainer position="bottom-center" />
      <div className="main-content">
        <div className="form-container">
          <h1 className="title">Goodwill Coupon Search</h1>
          <form className="form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Display Order ID:</label>

              <input
                type="text"
                value={displayOrderId}
                onChange={(e) => setDisplayOrderId(e.target.value)}
                className="input"
                required
              />
            </div>

            <button type="submit" className="button assign-button" disabled={wholesalePurchaseOrderSearchLoading}>
              {wholesalePurchaseOrderSearchLoading ? 'Searching...' : 'Search'}
            </button>
          </form>
          {wholesalePurchaseOrderSearchError && <p className="error">Error fetching data.</p>}
          <div>
            <h1>Select Order to reward Coupon</h1>
            <ul
              style={{
                listStyleType: 'none',
                padding: 0,
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '10px',
              }}
            >
              {wholesalePurchaseOrderSearchData &&
                Array.isArray(wholesalePurchaseOrderSearchData) &&
                wholesalePurchaseOrderSearchData?.map(
                  (value: WholesalePurchaseOrder, index: number, array: WholesalePurchaseOrder[]) => (
                    <WholesalePurchaseOrderCard
                      key={index}
                      order={value}
                      selectPurchaseOrderId={selectPurchaseOrderId}
                      handleOrderSelection={handleOrderSelection}
                    />
                  ),
                )}
            </ul>
          </div>
        </div>
        {
          <div className="items-container">
            <h2>Select One of Goodwill Coupon</h2>
            <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
              {/* <h1 style={{ color: '#007BFF' }}>Goodwill Coupons</h1> */}

              {goodwillCouponsLoading && <p>Loading coupons...</p>}
              {goodwillCouponsError && <p>Error fetching coupons.</p>}

              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {goodwillCouponsData &&
                  Array.isArray(goodwillCouponsData) &&
                  goodwillCouponsData?.map((value: YuorderCoupon, index: number, array: YuorderCoupon[]) => (
                    <li
                      key={index}
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '10px',
                        border: '1px solid #007BFF',
                        borderRadius: '5px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#f8f9fa',
                        //   display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() => handleCheckboxChange(value.id)}
                    >
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedCouponId == value.id}
                          onChange={() => handleCheckboxChange(value.id)}
                          style={{ marginRight: '10px', pointerEvents: 'none' }}
                        />
                        {value.title} ({value.maxDiscountAmount} ₱)
                      </div>

                      <div>{value.description}</div>
                    </li>
                  ))}
              </ul>
            </div>

            {goodwillCouponsData && Array.isArray(goodwillCouponsData) && goodwillCouponsData?.length > 0 && (
              <button
                onClick={handleAssignCoupon}
                className="button assign-button"
                disabled={goodwillCouponRewardLoading}
              >
                {goodwillCouponRewardLoading ? 'Loading' : '   Assign Coupon'}
              </button>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default GoodwillCoupon;
